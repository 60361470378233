import React from "react";
import {
  DialogActions,
  CircularProgress,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoginField from "../../molecules/CustomTextField/CustomLoginField";
import CustomPasswordField from "../../molecules/CustomTextField/CustomPasswordField";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";
import {
  StyledDialogRegister,
  StyledDialogTitleRegisterI,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
} from "./LoginDialogStyled";

const RegisterDialog = ({
  open,
  onClose,
  onRegister,
  username,
  setUsername,
  newPassword,
  setNewPassword,
  retypePassword,
  setRetypePassword,
  isPasswordMatch,
  setIsPasswordMatch,
  loading,
  setLoading,
  APIError,
  setAPIError,
  isEmailValid,
  setIsEmailValid,
  setOpenLoginDialog,
  transit,
  setTransit,
  setOpenVerificationDialog,
  sendVerificationEmail,
}) => {
  const [isChecked, setIsChecked] = React.useState(false); // State for checkbox
  const [isAccepted, setIsAccepted] = React.useState(false); // State for disclaimer

  const handleCheckboxClick = () => {
    if (isAccepted) {
      setIsChecked(false);
      setIsAccepted(false);
    } else {
      setIsChecked(!isChecked);
    }
  };

  const handleDisclaimerAccept = () => {
    setIsChecked(false);
    setIsAccepted(true);
  };

  const handleDisclaimerReject = () => {
    setIsChecked(false);
    setIsAccepted(false);
  };

  const handleEmailChange = (value) => {
    setLoading(false);
    setUsername(value);
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
  };

  const handlePasswordChange = (value) => {
    setLoading(false);
    setNewPassword(value);
    setAPIError(false);
    setIsPasswordMatch(null);
  };

  const handleReTypePasswordChange = (value) => {
    setLoading(false);
    setRetypePassword(value);
    setAPIError(false);
    setIsPasswordMatch(null);
  };

  const handleLoginClick = async () => {
    setTransit(true);
    setIsAccepted(false);
    setIsChecked(false);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenLoginDialog(true);
    setTransit(false);
    setNewPassword("");
    setRetypePassword("");
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
  };

  return (
    <StyledDialogRegister open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <StyledDialogTitleRegisterI>
        <Typography variant="h6" align="center">
          Create your account
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          We recommend using your personal email and strong password.
        </Typography>
      </StyledDialogTitleRegisterI>
      {isChecked ? (
        <StyledDialogContent style={{ backgroundColor: "#f0f4f9" }}>
          <Typography variant="subtitle2" gutterBottom>
            DISCLAIMER
          </Typography>
          <Typography variant="body2" gutterBottom>
            By proceeding, you acknowledge and agree that the information
            provided within this application is for informational purposes only
            and does not constitute financial, investment, or legal advice. You
            further confirm that you have read and understood the app's Terms
            and Conditions and Privacy Policy, and agree to abide by them.
            Investing involves risks, including the potential loss of principal,
            and you should consult with a qualified financial advisor before
            making any investment decisions. The app and its developers shall
            not be held liable for any financial decisions or actions taken
            based on the information provided herein.
          </Typography>
          <DialogActions>
            <Typography
              variant="body2"
              component="a"
              href="/termsandconditions"
              target="_blank"
              rel="noopener"
              style={{
                marginRight: "auto", // Aligns the link to the left
                textDecoration: "underline",
                cursor: "pointer",
                color: "grey",
              }}
            >
              Read Terms and Conditions
            </Typography>
            <Button onClick={handleDisclaimerReject} sx={{ color: "grey" }}>
              Reject
            </Button>
            <Button
              variant="contained"
              onClick={handleDisclaimerAccept}
              color="success"
            >
              Accept
            </Button>
          </DialogActions>
        </StyledDialogContent>
      ) : (
        <StyledDialogContent>
          <CustomLoginField
            value={username}
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter email"
          />
          <CustomPasswordField
            value={newPassword}
            placeholder="Enter Password"
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <CustomPasswordField
            value={retypePassword}
            placeholder="Re-enter Password"
            onChange={(e) => handleReTypePasswordChange(e.target.value)}
          />
          {APIError && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> {APIError}
            </Alert>
          )}
          {isEmailValid === false && (
            <Alert severity="warning" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Please provide a genuine email address.
            </Alert>
          )}
          {isPasswordMatch === false && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Passwords do not match.
            </Alert>
          )}
          {/* Checkbox for terms and conditions */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isAccepted}
                onChange={handleCheckboxClick}
                sx={{
                  color: "#007f06", // Default color
                  "&.Mui-checked": {
                    color: "#007f06", // Color when checked
                  },
                }}
              />
            }
            label="I agree to the terms and conditions"
            sx={{ marginTop: "10px" }}
          />
        </StyledDialogContent>
      )}
      <StyledDialogActions>
        {loading ? (
          <CustomButton color="log" variant="contained" disabled>
            <CustomThreeDotLoader />
          </CustomButton>
        ) : (
          <CustomButton
            color="log"
            variant="contained"
            onClick={onRegister}
            disabled={!isAccepted} // Disable if checkbox is not selected
          >
            Continue
          </CustomButton>
        )}
        <Typography variant="body2" style={{ marginTop: "20px" }}>
          Already a user?{" "}
          <Typography
            variant="body2"
            component="span"
            onClick={handleLoginClick}
            style={{
              color: "#007f06",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Login
          </Typography>
        </Typography>
      </StyledDialogActions>
    </StyledDialogRegister>
  );
};

export default RegisterDialog;
