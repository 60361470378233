import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, List, ListItemAvatar, Avatar, Stack } from "@mui/material";
import CustomTextField from "../../molecules/CustomTextField/CustomTextField";
import CustomAvatar from "../../molecules/CustomAvatar/CustomAvatar";
import LoadingAvatar from "../../molecules/LoadingAvatar/LoadingAvatar";
import ResponseLogo from "../../../assests/zackLogo.png";
import UserIcon from "../../../assests/user.png";
import SuggestionTile from "../../organisms/SuggestionTile";
import FeedbackTile from "../../organisms/FeedbackTile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomLinearProgress from "../../atoms/Progress/CustomLinearProgess";
import {
  StyledContainer,
  StyledParagraph,
  StyledPaper,
  StyledMessageContainer,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledNoMessagesText,
  StyledSubheading,
} from "./ChatInterfaceStyled";
import { fetchStream, fetchStreamMarkDown } from "../../../services/apiConfig";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import HotHandOff from "../../organisms/HotHandOff";

const ChatInterface = ({ onResponse, open, newMessage, setNewMessage }) => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState("Loading...");
  const [currentAbortController, setCurrentAbortController] = useState(null);
  const messagesEndRef = useRef(null); // Reference for scrolling
  const inputRef = useRef(null); // Reference for input field
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is small
  const [hothandOffDialogOpen, setHothandOffDialogOpen] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleServiceClick = () => {
    console.log("service is clicked");
    setHothandOffDialogOpen(true);
  };

  useEffect(() => {
    const questionTags = document.querySelectorAll(
      ".answer-message-content question"
    );

    const serviceTags = document.querySelectorAll(
      ".answer-message-content service, .answer-message-content advisor"
    );
    // Define the event handler function once
    const handleClick = (question) => {
      setNewMessage(question.innerHTML);
      // setSendMessage(!sendMessage);
    };
    // Add click event listener to each <question> tag
    questionTags.forEach((question) => {
      question.addEventListener("click", () => handleClick(question));
    });

    // Add click event listener to each <question> tag
    serviceTags.forEach((question) => {
      question.addEventListener("click", () => handleServiceClick(question));
    });
    // Cleanup the event listeners when the component unmounts or when message.text changes
    return () => {
      questionTags.forEach((question) => {
        question.removeEventListener("click", () => handleClick(question));
      });
    };
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Focus the input on component mount
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [newMessage]);

  // let currentAbortController = null;
  const handleSendMessage = () => {
    console.log("message sent to zack");
    // Select all <question> and <service> elements inside .answer-message-content
    const questionAndServiceTags = document.querySelectorAll(
      ".answer-message-content question, .answer-message-content service, .answer-message-content advisor"
    );

    // Loop through each element and hide it
    questionAndServiceTags.forEach((element) => {
      element.style.display = "none"; // Hide the element
    });
    // Abort the previous request if there's an ongoing one
    if (currentAbortController) {
      currentAbortController.abort();
    }

    // Create a new AbortController for the new request
    const newAbortController = new AbortController();
    setCurrentAbortController(newAbortController);

    if (newMessage.trim()) {
      const updatedMessages = messages.map((msg) => {
        return msg.isLoading === true
          ? {
              ...msg,
              text: "Request terminated. Please try again later.",
              isLoading: false, // Set isLoading to false
              isLoadingError: true,
            }
          : msg;
      });
      // Set updated messages
      setMessages([
        ...updatedMessages,
        { text: newMessage, isUser: true, isLoading: false },
      ]);
      // Clear the input
      setNewMessage(" ");

      // Display "loading..." message
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Loading........",
          isUser: false,
          isLoading: true,
          isStreaming: false,
        },
      ]);

      const email = localStorage.getItem("email");

      try {
        fetchStream(
          "getResponse",
          "POST",
          {
            prompt: newMessage,
            email: email,
            answer_format: "html",
          },
          setMessages,
          setStatus,
          newAbortController
        );
      } catch (error) {
        console.log(error);
      } finally {
        console.log("Request completed");
      }
    }
  };

  const renderMessage = (messageText, promptId) => {
    const questionIndex = messageText.indexOf("<question>");

    if (questionIndex === -1) {
      return (
        <>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className="answer-message-content"
          >
            {messageText}
          </ReactMarkdown>
          <FeedbackTile messageText={messageText} promptId={promptId} />
        </>
      );
    }

    const beforeQuestion = messageText.slice(0, questionIndex); // Text before <question>
    const afterQuestion = messageText.slice(questionIndex); // Text after <question> (including <question> tag)

    return (
      <>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="answer-message-content"
        >
          {beforeQuestion}
        </ReactMarkdown>
        <FeedbackTile messageText={messageText} promptId={promptId} />
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="answer-message-content"
        >
          {afterQuestion}
        </ReactMarkdown>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" style={{ height: "calc(100vh - 64px)" }}>
        <StyledPaper open={open} isMobile={isMobile}>
          <StyledMessageContainer>
            {messages.length > 0 ? (
              <StyledList>
                {messages.map((message, index) => (
                  <StyledListItem key={index}>
                    <ListItemAvatar>
                      {message.isUser ? (
                        <Avatar alt="User Icon" src={UserIcon} />
                      ) : message.isLoading && !message.isLoadingError ? (
                        <LoadingAvatar alt="Response Logo" src={ResponseLogo} />
                      ) : (
                        <CustomAvatar alt="Response Logo" src={ResponseLogo} />
                      )}
                    </ListItemAvatar>
                    <StyledListItemText
                      isQuestion={message.isUser}
                      primary={
                        <>
                          {message.isLoading ? (
                            <Stack
                              sx={{ width: "100%", color: "grey.500" }}
                              spacing={2}
                            >
                              <StyledListItemText
                                isQuestion={true}
                                primary={<>{status}</>}
                              ></StyledListItemText>
                              {message.isLoadingError ? (
                                <></>
                              ) : (
                                <CustomLinearProgress />
                              )}
                            </Stack>
                          ) : message.isUser ? (
                            <span
                              className="question-message-content"
                              dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                          ) : message.isFeedback ? (
                            // Use the renderMessage function to insert the FeedbackTile before <question> tag
                            <div>
                              {renderMessage(message.text, message.promptId)}
                            </div>
                          ) : (
                            <div>
                              <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                className="answer-message-content"
                              >
                                {message.text}
                              </ReactMarkdown>
                            </div>
                          )}
                        </>
                      }
                    />
                  </StyledListItem>
                ))}
                <div ref={messagesEndRef} />
              </StyledList>
            ) : isMobile ? (
              <>
                {" "}
                <List style={{ overflow: "hidden" }}>
                  <StyledNoMessagesText>Hello, I'm Zack!</StyledNoMessagesText>
                  {/* <StyledSubheading>How can I help you today?</StyledSubheading> */}
                </List>
                <SuggestionTile setNewMessage={setNewMessage} />
              </>
            ) : (
              <>
                {" "}
                <List style={{ overflow: "hidden", marginTop: "20px" }}>
                  <StyledNoMessagesText>Hello, I'm Zack</StyledNoMessagesText>
                  <StyledSubheading>How can I help you today?</StyledSubheading>
                </List>
                <SuggestionTile setNewMessage={setNewMessage} />
              </>
            )}
          </StyledMessageContainer>

          <StyledContainer>
            <CustomTextField
              inputRef={inputRef}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                  e.preventDefault();
                }
              }}
            />
            <StyledParagraph>
              {!isMobile &&
                "Zack information is provided 'as is' and solely for informational purposes and is delayed."}
              <Link
                target="_blank"
                to="/termsandconditions"
                style={{ textDecoration: "underline" }}
              >
                Terms & Conditions
              </Link>
            </StyledParagraph>
          </StyledContainer>
        </StyledPaper>
      </Container>
      <HotHandOff
        open={hothandOffDialogOpen}
        handleClose={() => setHothandOffDialogOpen(false)}
      />
    </>
  );
};

export default ChatInterface;
