import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}));

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "2vw",
  alignItems: "center",
  backgroundColor: "#fff",
  height: "10vh !important",
  padding: "1vh 2vw",
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "min(100%, 1350px)",
  height: "76vh",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
}));

export const LeftGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: theme.spacing(3),
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(2.5),
  maxWidth: "400px",
}));

export const RightGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px",
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  backgroundColor: "#f8f9fa",
  height: "12vh",
  maxHeight: "12vh",
  maxWidth: "100vw",
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "350px",
    overflow: "hidden",
    borderRadius: "12px",
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontSize: "20px",
}));

export const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  fontSize: "20px",
}));

export const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  marginBottom: "50px",
  width: "100%",
  justifyContent: "center",
}));

export const DialogFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f8f9fa",
  height: "50px",
  width: "100%",
  position: "absolute",
  bottom: "0",
}));
