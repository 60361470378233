import React from "react";
import { ListItemText, styled } from "@mui/material";

const ListItemTextStyled = styled(ListItemText)(({ open }) => ({
  opacity: open ? 1 : 0,
  "& .MuiListItemText-primary": {
    fontSize: "14px",
    fontFamily: "'Montserrat', sans-serif;",
    fontWeight: "200",
    color: "#ffffff",
    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
    overflow: "hidden", // Hides overflow text
    textOverflow: "ellipsis", // Displays ellipsis (...) when the text overflows
    maxWidth: "100%", // Ensures ellipsis behavior works within the container
  },
}));

function CustomListItem({ primary, open }) {
  return (
    <ListItemTextStyled
      primary={primary}
      open={open}
      // onClick={() => {
      //   setNewMessage(primary);
      // }}
    />
  );
}

export default CustomListItem;
