import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomThreeDotLoader from "../atoms/Progress/CustomThreeDotLoader";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

const HotHandOff = ({ open, handleClose }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const handleChatClick = async () => {
    console.log("Chat with expert clicked");
    setIsConnecting(true);
    await new Promise((resolve) => setTimeout(resolve, 4000));
    setIsConnected(true);
    setIsConnecting(false);
  };
  const handleCloseClick = () => {
    setIsConnecting(false);
    setIsConnected(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="service-dialog-title"
      aria-describedby="service-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleCloseClick}
        sx={{
          position: "absolute",
          right: "20px",
          top: "10px",
          color: "lightgray",
        }}
      >
        <CloseIcon />
      </IconButton>
      {isConnecting ? (
        <>
          {" "}
          <DialogTitle id="service-dialog-title" sx={{ marginTop: "25px" }}>
            Good choice!
          </DialogTitle>
          <DialogContent>
            <Typography id="service-dialog-description" sx={{ mb: 2 }}>
              I’m connecting you with one of our investment managers who will
              help you further.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="success"
              variant="contained"
              sx={{
                textTransform: "none",
                padding: "10px 60px",
                borderRadius: 2,
                fontWeight: "400",
              }}
            >
              Connecting you to expert{" "}
              <span style={{ marginLeft: "20px" }}>
                <CustomThreeDotLoader />
              </span>
            </Button>
          </DialogActions>
          <DialogContent></DialogContent>
        </>
      ) : isConnected ? (
        <>
          {" "}
          <DialogTitle id="service-dialog-title" sx={{ marginTop: "25px" }}>
            Schedule a Free Call
          </DialogTitle>
          <DialogContent>
            <Typography id="service-dialog-description" sx={{ mb: 2 }}>
              All our experts are currently assisting other clients. To ensure
              you receive personalized advice, please book a slot at your
              convenience.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              tabIndex={-1}
              startIcon={<EditCalendarIcon />}
              color="success"
              variant="contained"
              sx={{
                textTransform: "none",
                padding: "10px 60px",
                borderRadius: 2,
                fontWeight: "400",
              }}
              onClick={() =>
                window.open(
                  "https://calendly.com/zacks-investment-management/30min?month=2024-12",
                  "_blank"
                )
              }
            >
              Schedule Your Free Call
            </Button>
          </DialogActions>
          <DialogContent>
            <Typography variant="caption" color="text.secondary">
              By scheduling, you are confirming that you have read, understood
              and agree to Zack terms and conditions and the *Consent Policy set
              out below.
            </Typography>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="service-dialog-title" sx={{ marginTop: "25px" }}>
            I see you're interested in detailed stock analysis.
          </DialogTitle>
          <DialogContent>
            <Typography id="service-dialog-description" sx={{ mb: 2 }}>
              Would you like to speak with one of our expert investment managers
              for personalized advice?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: 2,
                color: "grey",
                borderColor: "grey",
                border: "1px solid grey",
                padding: "10px 60px",
                "&:hover": {
                  bgcolor: "#d6d6d6", // Slightly darker grey on hover
                  border: "1px solid grey",
                  color: "black",
                },
              }}
            >
              Go back to chat
            </Button>
            <Button
              onClick={handleChatClick}
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: 2,
                padding: "10px 60px",
              }}
            >
              Chat with expert
            </Button>
          </DialogActions>
          <DialogContent>
            <Typography variant="caption" color="text.secondary">
              By clicking "Chat with expert" you agree to our Terms and
              Condition.
            </Typography>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default HotHandOff;
