import React, { useState } from "react";
import {
  Select,
  Menu,
  MenuItem,
  FormControl,
  Typography,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ZackUpgrade = () => {
  const [plan, setPlan] = useState("Zack");

  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  return (
    <FormControl variant="outlined">
      <Select
        value={plan}
        onChange={handleChange}
        IconComponent={ArrowDropDownIcon}
        displayEmpty
        renderValue={(selected) => (
          <Typography style={{ fontSize: "18px" }}>{selected}</Typography>
        )}
        sx={{
          border: "none", // Removes the border
          "& fieldset": {
            border: "none", // Removes the border from the inner fieldset
          },
          "& .MuiSelect-select": {
            paddingRight: 0, // Ensures no extra padding on the right
            padding: "0px",
          },
        }}
      >
        <MenuItem value="Gemini">
          <Typography style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 8 }}>✨</span> Zack
          </Typography>
        </MenuItem>
        <MenuItem value="GeminiAdvanced" disabled>
          <Typography style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 8, color: "pink" }}>✨</span> Zack
            Premium
          </Typography>
          <Button
            variant="outlined"
            size="small"
            style={{ marginLeft: "auto" }}
          >
            Upgrade
          </Button>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ZackUpgrade;
