import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import ZackUpgrade from "./ZackUpgrade";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserIcon from "../../assests/user.png";
import zackNewLogo from "../../assests/zackNewLogo.png";

export default function UserMenu({
  anchorEl,
  handleMenu,
  handleClose,
  handleLogout,
  onOpen,
  onClose,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is small
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "25px",
        height: "64px",
        minHeight: "64px !important",
        borderBottom: "2px solid #5C5C5C",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onClose}
            edge="start"
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          // marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            // background: "linear-gradient(to bottom,#ec7a10 0,#b45b08 100%)",
            background: "#fff",
            boxShadow: "none",
            color: "#000",
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "14px",
            marginRight: "30px",
            fontWeight: "bold",
            border: "2px solid #5d5d5d",
            backgroundColor: "rgba(5,58,39,0)",
            // padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#dde3ea",
            },
          }}
          // onClick={handleMenu}
        >
          <img alt="User Icon" src={zackNewLogo} style={{ width: "20px" }} />
          <Typography style={{ marginLeft: "10px" }}>Try Premium</Typography>
        </Button>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{ border: "2px solid grey", padding: "2px" }}
        >
          {/* <UserIcon /> */}
          <Avatar alt="User Icon" src={UserIcon} />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                "& .MuiListItemIcon-root": {
                  fontSize: "12px",
                  width: 25,
                  minWidth: "25px !important",
                },
                "& li.MuiButtonBase-root": {
                  fontSize: "12px",
                },
                "& .MuiAvatar-root": {
                  width: 25,
                  height: 25,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Avatar />
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
}
