import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Box, Card, CardContent } from "@mui/material";
import { fetchData } from "../../services/apiConfig";
import CustomSuggestionProgress from "../atoms/Progress/CustomSuggestionProgress";
import { Lightbulb, FlashOn, Warning } from "@mui/icons-material";

const features = [
  {
    icon: <Lightbulb fontSize="large" color="primary" />,
    title: "Examples",
    points: [
      "Advice is general and not personalized to individual goals or risk tolerance",
      "Information may be inaccurate, incomplete, or outdated (AI can produce hallucinations).",
      "Recommendations are AI-generated with no human oversight",
    ],
  },
  {
    icon: <FlashOn fontSize="large" color="primary" />,
    title: "Constraints",
    points: [
      "Investment risk: You can lose money, past performance doesn't guarantee future results.",
      "Data limitations and biases may affect AI-generated advice.",
    ],
  },
  {
    icon: <Warning fontSize="large" color="primary" />,
    title: "Limitations",
    points: [
      "Don't rely solely on AI; consider professional advice for personal financial decisions.",
      "Recommendations may not align with your individual financial situation or diversification needs.",
    ],
  },
];

const QuestionTile = ({ question, setNewMessage, alignLeft }) => (
  <Paper
    elevation={3}
    sx={{
      padding: "10px",
      textAlign: "left",
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
      backgroundColor: "#e0e0e0",
      borderRadius: "5px",
      boxShadow: "none",
      cursor: "pointer",
      alignSelf: alignLeft ? "flex-start" : "flex-end", // Align left or right
      "&:hover": {
        backgroundColor: "#cbcbcb",
        background: "linear-gradient(0deg, #cbcbcb 0%, #dfdfdf 100%)",
      },
    }}
    onClick={() => setNewMessage(question)}
  >
    <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "normal" }}>
      {question}
    </Typography>
  </Paper>
);

const FeatureCard = ({ icon, title, points }) => (
  <Card
    sx={{
      textAlign: "center",
      borderRadius: 2,
      boxShadow: "none",
    }}
  >
    <CardContent style={{ padding: "2px" }}>
      {/* <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
        {icon}
      </Box> */}
      {/* <Typography variant="h6" gutterBottom>
        {title}
      </Typography> */}
      {points.map((point, index) => (
        <Typography
          key={index}
          variant="body2"
          color="textSecondary"
          style={{
            backgroundColor: "white",
            marginBottom: "5px",
            padding: "5px",
            border: "1px solid #f0f4f9",
            borderRadius: "5px",
            fontSize: "0.7rem",
            textAlign: "left",
            color: "#7D7D7D",
          }}
        >
          {point}
        </Typography>
      ))}
    </CardContent>
  </Card>
);

const SuggestionTile = ({ setNewMessage }) => {
  const [suggestQuestions, setSuggestQuestions] = useState([]);

  useEffect(() => {
    // Fetch the suggested questions on component mount
    try {
      const email = localStorage.getItem("email");
      if (email) {
        fetchData("getSuggest", "POST", { email: email }).then((response) => {
          setSuggestQuestions(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box sx={{ marginTop: "0px" }}>
      {/* <Typography variant="body" sx={{ marginBottom: "0px" }}>
        Disclaimer:
      </Typography> */}

      {localStorage.getItem("email") === "undefined" ? (
        <Grid container justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <FeatureCard {...feature} />
            </Grid>
          ))}{" "}
        </Grid>
      ) : (
        <></>
      )}

      <Typography
        variant="h6"
        sx={{
          marginBottom: "16px",
          marginTop: "25px",
          fontFamily: "Inter",
          letterSpacing: "3px",
          fontWeight: "500",
          color: "#369902",
        }}
      >
        POPULAR QUESTIONS
      </Typography>

      <Grid container spacing={2}>
        {suggestQuestions.length > 0 ? (
          suggestQuestions.map((item, index) => (
            <Grid
              item
              xs={12} // Full width on small screens
              sm={12} // Full width on small screens
              md={6} // 50% width on medium screens
              lg={6} // 50% width on large screens
              key={index}
            >
              <QuestionTile
                question={item.prompt}
                setNewMessage={setNewMessage}
                alignLeft={index % 2 === 0} // Align left for even indexes, right for odd
              />
            </Grid>
          ))
        ) : (
          <CustomSuggestionProgress />
        )}
      </Grid>
    </Box>
  );
};

export default SuggestionTile;
