import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import TnC from "./pages/TnC/TnC";
import AuthProvider, { AuthContext } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Protected routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <GuestOnlyRoute>
                <Login />
              </GuestOnlyRoute>
            }
          />

          {/* Public route */}
          <Route path="/termsandconditions" element={<TnC />} />

          {/* Redirect unknown paths */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

// Handles logged-in-only routes
function ProtectedRoute({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (!isLoggedIn) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" replace />;
  }

  return children;
}

// Handles routes restricted to non-logged-in users
function GuestOnlyRoute({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (isLoggedIn) {
    // Redirect to home if user is already authenticated
    return <Navigate to="/" replace />;
  }

  return children;
}

export default App;
