import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import zacksLogo2 from "../../../assests/zackLogo2.png";

function CustomTextField(props) {
  return (
    <TextField
      variant="filled"
      placeholder="Enter your question here ..."
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img
              src={zacksLogo2}
              alt="icon"
              style={{
                width: "44px", // Adjust width
                // height: "24px", // Adjust height
              }}
            />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiFilledInput-root": {
          borderRadius: "8px", // Rounded corners
          minHeight: "64px",
          border: "2px solid #b5b5b5",
          backgroundColor: "#cbcbcb", // Background color of the input field
          background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
          color: "#3d3d3d",
          height: "100px",
          "&:hover": {
            backgroundColor: "#cbcbcb", // Same background color on hover
            border: "2px solid #b5b5b5",
          },
          "&.Mui-focused": {
            backgroundColor: "#cbcbcb", // Same background color on focus
            border: "2px solid #b5b5b5",
          },
          "&::before, &::after": {
            border: "none",
            display: "none",
            // borderBottom: '2px solid #f0f4f9', // Bottom border color
          },
          "&:hover::before": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on hover
          },
          "&.Mui-focused::after": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on focus
          },
        },
        "& .MuiFilledInput-input": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#3d3d3d",
        },
      }}
      {...props}
    />
  );
}

export default CustomTextField;
