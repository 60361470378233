import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import zacks from "../../assests/zacks.png";
import CustomButton from "../../components/atoms/Buttons/CustomButtons";
import {
  Header,
  StyledContainer,
  LeftGrid,
  ContentBox,
  RightGrid,
  Footer,
} from "./LoginStyles";
import LoginDialog from "../../components/templates/LoginDialog/LoginDialog";
import RegisterDialog from "../../components/templates/LoginDialog/RegisterDialog";
import VerificationDialog from "../../components/templates/LoginDialog/VerificationDialog";
import ResestPasswordDialog from "../../components/templates/LoginDialog/ResetPasswordDialog";
import { fetchAuthData, fetchAuthDataLogin } from "../../services/apiConfig";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transit, setTransit] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(null);
  const [APIError, setAPIError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState("");
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [retypeResetPassword, setRetypeResetPassword] = useState("");
  const [passwordResetDone, setPasswordResetDone] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get("token");
  const resetpassParam = searchParams.get("resetpass");
  useEffect(() => {
    console.log(tokenParam);
    console.log(location);
    const verifyToken = async () => {
      if (tokenParam && resetpassParam) {
        console.log("Trying to re-set password");
        setOpenResetPasswordDialog(true);
      } else if (tokenParam && !resetpassParam) {
        // setOpenDialog(true); // Uncomment if you want to show a dialog on token verification
        try {
          // Simulate network delay to test token verification
          console.log("Trying to verify token");
          const response = await fetchAuthData("verifyToken", "POST", null, {
            token: tokenParam,
          });
          console.log(response);
          if (response.detail && response.detail !== "Email verified") {
            // Token verification failed, handle accordingly (e.g., show error message)
            console.log("Token verification failed:", response.detail);
            setVerificationError(response.detail);
            handleLoginClick();
          } else {
            console.log("Token verification successful");
            login(response.email);
            navigate("/", { replace: true });
          }
        } catch (error) {
          console.error("Error during login:", error);
        } finally {
          // setLoading(false); // Uncomment if you are managing loading state
        }
      }
    };

    verifyToken();
  }, [location]);

  const handleLoginClick = () => setOpenLoginDialog(true);
  const handleRegisterClick = () => setOpenRegisterDialog(true);
  const handleCloseDialog = () => {
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenVerificationDialog(false);
    setOpenResetPasswordDialog(false);
    setVerificationError("");
    setTransit(false);
    setNewPassword("");
    setRetypePassword("");
    setAPIError(false);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
    setLoading(false);
    setAPIError(false);
    setPassword("");
    setUsername("");
    setIsResettingPassword(false);
    setResetPassword("");
    setRetypeResetPassword("");
    setPasswordResetDone(false);
  };

  const validateEmail = (email) => {
    console.log("validating email");
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(re.test(email));
    return re.test(email);
  };

  const handleVerifyDialog = async () => {
    if (validateEmail(username)) {
      console.log("verification pending");
      setOpenLoginDialog(false);
      setOpenRegisterDialog(false);
      setOpenVerificationDialog(true);
      setTransit(false);
      setPassword("");
      setVerificationError("");
      // setVerificationFailed(false);
    } else {
      console.log("Invalid email address");
      setVerificationError("");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleSendVerificationDialogEmail = async () => {
    setAPIError(false);
    handleVerifyDialog();
    sendVerificationEmail();
  };

  const handlePasswordSendVerificationDialog = async () => {
    setAPIError(false);
    setIsResettingPassword(true);
    handleVerifyDialog();
    sendPasswordVerificationEmail();
  };

  const handleLogin = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        // Simulate network delay
        // await new Promise((resolve) => setTimeout(resolve, 5000000));
        console.log("trying to log in");
        const response = await fetchAuthDataLogin("login", "POST", {
          grant_type: "password",
          username: username,
          password: password,
          scope: "",
          client_id: "string",
          client_secret: "string",
        });
        if (response) {
          console.log(response);
          if (
            response.detail === "LOGIN_USER_NOT_VERIFIED" ||
            response.detail === "LOGIN_BAD_CREDENTIALS"
          ) {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            response.detail === "LOGIN_USER_NOT_VERIFIED"
              ? setAPIError("Please verify your email address.")
              : setAPIError("Invalid username or password.");
            console.log("User not verified or enabled.");
          } else {
            setOpenLoginDialog(false);
            login(username);
            navigate("/", { replace: true });
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError("An error has occurred. Please try again.");
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      console.log("Invalid email address");
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleRegister = async () => {
    if (validateEmail(username)) {
      if (
        newPassword === retypePassword &&
        newPassword.length !== 0 &&
        retypePassword.length !== 0
      ) {
        setLoading(true);
        try {
          console.log("trying to register");
          const response = await fetchAuthData("register", "POST", {
            email: username,
            password: newPassword,
            is_active: true,
            is_superuser: false,
            is_verified: false,
          });
          if (response) {
            console.log(response);
            if (response.detail) {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              response.detail === "REGISTER_USER_ALREADY_EXISTS"
                ? setAPIError("User already exists.")
                : setAPIError("Please enter a valid input.");
              console.log("Please enter a valid input.");
            } else {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              handleVerifyDialog();
            }
          }
          console.log(response);
        } catch (error) {
          console.error("Error during register:", error);
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("An error has occurred. Please try again.");
        } finally {
          setLoading(false);
          setTransit(false);
        }
      } else {
        console.log("Password dont match.");
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setIsPasswordMatch(false);
        setTransit(false);
      }
    } else {
      console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const sendVerificationEmail = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        console.log("trying to send verification email");
        const response = await fetchAuthData(
          "requestVerifyToken",
          "POST",
          null,
          {
            email: username,
          }
        );
        if (response) {
          console.log(response);
          if (response.detail) {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            setAPIError("Verification email sent");
            console.log("Verification email sent");
          } else {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            setAPIError("There was an error sending the verification email.");
          }
        }
      } catch (error) {
        console.error("Error during sending out verification:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError(
          "Error during sernding out verification. Please try again."
        );
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const sendPasswordVerificationEmail = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        console.log("trying to send verification email");
        const response = await fetchAuthData(
          "requestPasswordVerification",
          "POST",
          {
            email: username,
          }
        );

        if (response === null) {
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("Verification email sent");
          console.log("Verification email sent");
          console.log(response);
        } else {
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("There was an error sending the verification email.");
        }
      } catch (error) {
        console.error("Error during sending out verification:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError(
          "Error during sernding out verification. Please try again."
        );
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleResetPassword = async () => {
    if (
      resetPassword === retypeResetPassword &&
      resetPassword.length !== 0 &&
      retypeResetPassword.length !== 0
    ) {
      if (resetPassword.length > 8) {
        setLoading(true);
        try {
          console.log("resetting password");
          const response = await fetchAuthData("resetPassword", "POST", {
            token: tokenParam,
            password: resetPassword,
          });
          if (response) {
            console.log(response);
            if (response.detail) {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              response.detail === "RESET_PASSWORD_BAD_TOKEN"
                ? setAPIError(
                    "Its a expired token. Please re-try with a new token."
                  )
                : setAPIError("An error has occurred. Please try again.");
            } else {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              setPasswordResetDone(true);
              handleLoginClick();
            }
          } else {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            setPasswordResetDone(true);
            handleLoginClick();
          }
          console.log(response);
        } catch (error) {
          console.error("Error during re-set password:", error);
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("An error has occurred. Please try again.");
        } finally {
          setLoading(false);
          setTransit(false);
        }
      } else {
        console.log("Short password");
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError("Password shorter than 8 characters.");
        setTransit(false);
      }
    } else {
      console.log("Password dont match.");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsPasswordMatch(false);
      setTransit(false);
    }
  };

  return (
    <Box style={{ overflow: "hidden" }}>
      <Header>
        <Typography sx={{ marginRight: "auto" }}>ZACKS</Typography>
        <CustomButton color="blacktext" variant="text">
          Try Zack Premium
        </CustomButton>
        <CustomButton color="blacktext" variant="text">
          FAQ
        </CustomButton>
        <CustomButton
          color="blacktext"
          variant="text"
          onClick={handleRegisterClick}
        >
          Register
        </CustomButton>
        <CustomButton
          color="green"
          variant="contained"
          onClick={handleLoginClick}
        >
          Sign in
        </CustomButton>
      </Header>
      <StyledContainer maxWidth={false}>
        <Grid container>
          <LeftGrid item xs={12} sm={12} md={6} lg={6}>
            <ContentBox>
              <img
                src={zacks}
                alt="Login"
                style={{ maxWidth: "100%", height: "88px", width: "400px" }}
              />
              <Typography variant="h5" align="left">
                AI powered tool to informed investing.
              </Typography>
              <Typography variant="h6" align="left">
                Chat to navigate investment research reports and more with Zack
                AI
              </Typography>
              <Box>
                <CustomButton
                  color="outline"
                  variant="contained"
                  onClick={handleRegisterClick}
                  style={{ marginRight: "8px" }}
                >
                  Register
                </CustomButton>

                <CustomButton
                  color="green"
                  variant="contained"
                  onClick={handleLoginClick}
                >
                  Sign in
                </CustomButton>
              </Box>
            </ContentBox>
          </LeftGrid>
          <RightGrid item xs={12} sm={12} md={6} lg={6}>
            <iframe
              width="627"
              height="346.5"
              src="https://www.youtube.com/embed/QBspKWJk16o?si=3n8w2jB7534s5-0y"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </RightGrid>
        </Grid>
      </StyledContainer>
      <Footer>
        <Typography
          variant="h6"
          align="left"
          color="#444746"
          sx={{ padding: "30px" }}
          component={Link}
          to="/termsandconditions"
          target="_blank"
        >
          Privacy & Terms
        </Typography>
      </Footer>

      {/* Login Dialog */}
      <LoginDialog
        open={openLoginDialog}
        onClose={handleCloseDialog}
        onLogin={handleLogin}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenRegisterDialog={setOpenRegisterDialog}
        transit={transit}
        setTransit={setTransit}
        sendVerificationEmail={handleSendVerificationDialogEmail}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        verificationError={verificationError}
        setVerificationError={setVerificationError}
        passwordResetDone={passwordResetDone}
      />

      {/* Register Dialog */}
      <RegisterDialog
        open={openRegisterDialog}
        onClose={handleCloseDialog}
        onRegister={handleRegister}
        username={username}
        setUsername={setUsername}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        retypePassword={retypePassword}
        setRetypePassword={setRetypePassword}
        isPasswordMatch={isPasswordMatch}
        setIsPasswordMatch={setIsPasswordMatch}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenLoginDialog={setOpenLoginDialog}
        transit={transit}
        setTransit={setTransit}
        setOpenVerificationDialog={setOpenVerificationDialog}
        sendVerificationEmail={handleSendVerificationDialogEmail}
      />

      <VerificationDialog
        open={openVerificationDialog}
        onClose={handleCloseDialog}
        setOpenVerificationDialog={setOpenVerificationDialog}
        setOpenRegisterDialog={setOpenRegisterDialog}
        setOpenLoginDialog={setOpenLoginDialog}
        username={username}
        transit={transit}
        setTransit={setTransit}
        loading={loading}
        sendVerificationEmail={sendVerificationEmail}
        APIError={APIError}
        isResettingPassword={isResettingPassword}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        // verificationFailed={verificationFailed}
      />

      <ResestPasswordDialog
        open={openResetPasswordDialog}
        onClose={handleCloseDialog}
        handleResetPassword={handleResetPassword}
        resetPassword={resetPassword}
        setResetPassword={setResetPassword}
        retypeResetPassword={retypeResetPassword}
        setRetypeResetPassword={setRetypeResetPassword}
        isPasswordMatch={isPasswordMatch}
        setIsPasswordMatch={setIsPasswordMatch}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenRegisterDialog={setOpenRegisterDialog}
        transit={transit}
        setTransit={setTransit}
        sendVerificationEmail={handleSendVerificationDialogEmail}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        verificationError={verificationError}
        setVerificationError={setVerificationError}
        setOpenLoginDialog={setOpenLoginDialog}
      />
    </Box>
  );
};

export default Login;
